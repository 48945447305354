import './auth.scss';
import React from 'react'
import { Layout } from 'antd'
import { Suspense } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useContext } from 'react';
import DomainContext from '../app-global/DomainContext';
import { useEffect } from 'react';

const SignUp = React.lazy(() => import('./SignUp'));

const Auth = (props) => {

    const history = useHistory();
    const domainDetails = useContext(DomainContext)

    useEffect(() => {
        if(props.location && props.location.pathname === '/'){
            history.push("/signup");
        }
    })

    const getPathName = (name) => {
        if(props.location.pathname.indexOf(name) >= 0){
            return true;
        }
        return false;
    }

    return (
        <Layout className="authLayout">
            <div className="authBox">
                <div className="victorLogo" style={{ textAlign: 'center' , backgroundImage: `url(${domainDetails?.cdnDomain + domainDetails?.logo})`}}></div>
                <Suspense fallback={<div>Loading...</div>}>
                    {(getPathName('/signup')) ? <SignUp /> : null}
                </Suspense>
            </div>
        </Layout>
    )
}


export default withRouter(Auth);