import './App.scss';
import auth from './app-auth/authPage';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Favicon from 'react-favicon'
import Api from './service/api';
import DomainContext from './app-global/DomainContext';

const api = new Api();

function App() {

  const [domainDetails, setDomainDetails] = useState();

  useEffect(() => {
    getDomainInfo();
    // eslint-disable-next-line
  }, [])

  const getDomainInfo = async () => {
    const url = 'auth/domain';
    let result = await api.mainRestCallService(url, 'GET');
    if (result?.data) {
      var data = JSON.parse(JSON.stringify(result.data));
      data = JSON.parse(data);
      setDomainDetails(data);
      document.title = data.title.concat(' | ').concat('Onboarding')
      const linkElement = document.createElement("link");
      linkElement.setAttribute("rel", "stylesheet");
      linkElement.setAttribute("type", "text/css");
      linkElement.setAttribute("href", data.cdnDomain + data.css);
      document.head.appendChild(linkElement);
    }
  }

  return (
    <div id="domainClass" className="default">
      {
        domainDetails ?
          <DomainContext.Provider value={domainDetails}>
            <Favicon url={domainDetails?.cdnDomain + domainDetails?.favIcon} />
            <HashRouter>
              <Switch>
                <Route path="/signup" component={auth} />
                <Route path="/" exact component={auth} />
              </Switch>
            </HashRouter>
          </DomainContext.Provider>
          : null
      }
    </div>
  );
}

export default App;

