import axios from 'axios';
import { notification} from 'antd';

var isRefreshing = false;

export default class Api {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.origin + '/';
  }

  /**************************************************************************************
   ** mainRestCallService
   ** @param {*} apiPath : api end point
   ** @param {*} method : POST, PUT, DELETE, GET
   ** @param {*} body : request body if not send goes as null
   ** @param {*} asBody : By default true, if false goes as url params.
   ** @returns
   *************************************************************************************/
   mainRestCallService = async (apiPath, method, body = null, asParams = false) => {
    var options = {
      method: '',
      body: '',
      headers: ''
    };
    var url = this.api_url + apiPath;
    options.method = method;
    if (asParams === true) {
      url = new URL(`${this.api_url}${apiPath}`)
      Object.keys(body).forEach(key => {
        const value = body[key];
        url.searchParams.append(key, value ? value.toString() : '')
      });
    } else {
      options.body = body ? JSON.stringify(body) : null;
    }

    var result;
    if(method === 'POST'){
      result = await this.postMethod(url , body)
    } else if (method === 'GET'){
      result = await this.getMethod(url)
    } else if (method === 'PUT') {
      result = await this.putMethod(url, body)
    }

    if(result === 'session_expired'){
      notification.error({message: 'Session Expired'})
    } else if (result && result.status !== 1) {
      return result.error?.message ?? 'Unsuccessful response';
    } else {
      return result;
    }
  }


  // get Method
  getMethod = (url) => { return axios.get(url); }

  // Post Method
  postMethod = (url , body) => { return axios.post(url , body); }

  // Put Method
  putMethod = (url , body) => { return axios.put(url , body); }

  downloadFile = async (Apiurl, fileName) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.origin + '/';
    fetch(url + Apiurl, { method: 'GET', headers: { 'Content-Type': 'application/pdf', "Authorization": `Token ${sessionStorage.getItem('access_token')}` } })
      .then((response) => response.blob()).then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName.concat('.pdf'),
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  }

}


const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.origin + '/',
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.request.use(async config => {
  const token = sessionStorage.getItem('access_token');
    if (token) { config.headers["Authorization"] = `Token ${token}`; }
    return config;
  },
  error => { return Promise.reject(error);}
);

axios.interceptors.response.use(response => {
    return response.data;
  },
  async function(error) {
    const originalConfig = error.config;
    if (error.response?.status === 401 && isRefreshing === false) {
      isRefreshing = true;
      originalConfig._retry = true;
      try {
        const refreshToken = sessionStorage.getItem('refresh_token');
        const response = await instance.post("/auth/refresh", {
          token: refreshToken,
        });
        sessionStorage.setItem('access_token' , response.data.access_token)
        sessionStorage.setItem('refresh_token' , response.data.refresh_token)
        isRefreshing = false;
        return instance(originalConfig);
      } catch (_error) {
        isRefreshing = false;
        window.location = '#/login';
        return 'session_expired';
      }
    };
    return error.response?.data;
  }
);
